<template>
	<div class="body-content">
		<div style="height: 500px;"></div>
	</div>
</template>

<script>
	export default {
		name: 'Home',
		components: {
		},
		data() {
			return {
			}
		},
		created() {
			// 调用工具方法示例
			console.log(this.$util.testName());
		},
		mounted() {

		},
		methods: {
			
		}
	}
</script>

<style scoped lang="less">

</style>
